import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import FifaArabMain from "../../components/case-studies/fifa-arab-cup-2021/fifaArabMain"
import FifaArabInfo from "../../components/case-studies/fifa-arab-cup-2021/fifaArabInfo"
import FifaArabOverview from "../../components/case-studies/fifa-arab-cup-2021/fifaArabOverview"
import FifaArabScreen1 from "../../components/case-studies/fifa-arab-cup-2021/fifaArabScreen1"
import FifaArabSteps from "../../components/case-studies/fifa-arab-cup-2021/fifaArabSteps"
import FifaArabHandlingData from "../../components/case-studies/fifa-arab-cup-2021/fifaArabHandlingData"
import FifaArabResponsive from "../../components/case-studies/fifa-arab-cup-2021/fifaArabResponsive"
import FifaArabBlankCream from "../../components/case-studies/fifa-arab-cup-2021/fifaArabBlankCream"
import FifaArabScreenBottom from "../../components/case-studies/fifa-arab-cup-2021/fifaArabScreenBottom"
import FifaArabMoreCs from "../../components/case-studies/fifa-arab-cup-2021/fifaArabMoreCs"
import DirectContact from "../../components/global/directContact"

import csFifaAbrabCupFeaturedImage from "../../../static/case-studies/fifa-arab-cup-2021/fifa-ac-featured-thumbnail.png"
import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/fifa-arab-cup-2021.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csFifaAbrabCupFeaturedImage}
      title="Fan ID Platform for FIFA Arab Cup 2021 Case Study | UX/UI Design | Sports"
      description="How we designed Hay’ya Card (Fan ID) Platform for Fifa Arab Cup 2021"
    />
  </> 
)



const FifaArabCup2021 = () => {
    return (
      <Layout>
        <Seo
          bid="cs-fifa-arab-cup"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <FifaArabMain />
        <FifaArabInfo />
        <FifaArabOverview />
        <FifaArabScreen1 />
        <FifaArabSteps />
        <FifaArabHandlingData />
        <FifaArabResponsive />
        <FifaArabBlankCream />
        <FifaArabScreenBottom />
        <FifaArabMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default FifaArabCup2021
