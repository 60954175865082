import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const FifaArabResponsive = () => {
  return (
    <section className="fifa-ac-responsive pb-0">       
        <Container>
            <Row>
                <Col lg={6} className="offset-lg-1">
                    <h2 className="sub-title text-white">
                        Responsive designs that ensure fluid interaction 
                    </h2>                    
                </Col>
            </Row>
        </Container>
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/fifa-arab-cup-2021/fifa-ac-responsive.png" alt="Fifa Arab cup 2021 Handling Data Screens | WowMakers" placeholder="blurred" className="img-fluid" width={1440} height={998}/>
                </Col>                               
            </Row>
        </Container>
    </section>
  )
}

export default FifaArabResponsive
