import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const FifaArabOverview = () => {
  return (
    <section className="cs-overview">       
        <Container>
            <Row className="align-items-center">
                <Col lg={7} className="pl-lg-5 pl-3 pb-lg-0 pb-4">
                    <h2 className="sub-title">Overview</h2>
                    <p className="cs-sub-text pb-0">We had one aim - to gather millions of football fans around the globe on a single website and help them find their way to enjoy the glorious FIFA moments in person like true fans. </p>
                </Col>
                <Col lg={5} className="order-lg-first">
                    <StaticImage src="../../../images/case-studies/fifa-arab-cup-2021/fifa-ac-overview-1.png" alt="Fifa Arab cup 2021 WowMakers Overview 1" placeholder="blurred" className="img-fluid" width={486} height={389}/>
                </Col>
                <Col lg={5} className="pt-2">
                    <StaticImage src="../../../images/case-studies/fifa-arab-cup-2021/fifa-ac-overview-2.png" alt="Fifa Arab cup 2021 WowMakers Case study Overview 2" placeholder="blurred" className="img-fluid" width={778} height={190}/>
                </Col>                               
            </Row>
        </Container>
    </section>
  )
}

export default FifaArabOverview
