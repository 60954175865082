import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const FifaArabScreen1 = () => {
  return (
    <section className="fifa-ac-screen-1">       
        <Container>
            <Row className="justify-content-center">
                <Col lg={10} className="text-center">
                    <StaticImage src="../../../images/case-studies/fifa-arab-cup-2021/fifa-ac-screens-1-img.png" alt="Fifa Arab cup 2021 WowMakers Screens 1" placeholder="blurred" className="img-fluid" width={1050} height={592}/>
                </Col>                               
            </Row>
        </Container>
    </section>
  )
}

export default FifaArabScreen1
