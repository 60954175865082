import React from "react"

const FifaArabBlankCream = () => {
  return (
    <section className="blank-cream-bg pb-md-5 pt-md-5 pb-4 pt-4">       
    </section>
  )
}

export default FifaArabBlankCream
