import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FifaArabInfo = () => {
  return (
    <section className="cs-info position-relative">
        <div className="info-right">
            <img src="/case-studies/fifa-arab-cup-2021/fifa-ac-info-right-img.svg" className="img-fluid" alt="Fifa Arab cup 2021 WowMakers Case study | Design pattern 2"/>
        </div>        
        <Container className="position-relative">
            <Row className="pb-md-5 pb-0">
                <Col lg={8}>
                    <h1 className="cs-title px-0 col-md-8 u-section mb-4">Fan ID Platform Design for FIFA Arab Cup  2021</h1>
                    <p className="cs-sub-text mb-4">Our design team has creatively integrated the brand identity element of FIFA Arab Cup 2021 across the portal, without affecting the usability of the website.</p>
                </Col>
                <Col md={8} className="details-mini d-flex justify-content-between">
                    <div className="stats-info">
                        <p className="cs-sub-text st-title mb-0">Duration</p>
                        <p className="st-data mb-0">3 Months</p>
                    </div>
                    <div className="stats-info">
                        <p className="cs-sub-text st-title mb-0">Services</p>
                        <p className="st-data mb-0">UX/UI</p>
                    </div>
                    <div className="stats-info">
                        <p className="cs-sub-text st-title mb-0">Platform</p>
                        <p className="st-data mb-0">Web</p>
                    </div>												
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default FifaArabInfo
