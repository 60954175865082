import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const FifaArabScreenBottom = () => {
  return (
    <section className="fifa-ac-screens-bottom">       
        <Container fluid>
            <Row>
                <Col lg={12} className="text-center px-0">
                    <StaticImage src="../../../images/case-studies/fifa-arab-cup-2021/fifa-ac-bottom-screens.png" alt="Fifa Arab cup 2021 WowMakers Screens 2" placeholder="blurred" className="img-fluid" width={1429} height={1163}/>
                </Col>                               
            </Row>
        </Container>
    </section>
  )
}

export default FifaArabScreenBottom
