import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const FifaArabHandlingData = () => {
  return (
    <section className="fifa-ac-handling-data">       
        <Container fluid>
            <Row className="justify-content-center">
                <Col lg={6} className="text-center">
                    <h2 className="sub-title">
                        Handle data as smooth as a ball rolling on the field 
                    </h2>                    
                </Col>
                <Col lg={12} className="px-0 mt-md-5 mt-0">
                    <StaticImage src="../../../images/case-studies/fifa-arab-cup-2021/fifa-ac-handling-data.png" alt="Fifa Arab cup 2021 Handling Data Screens | WowMakers" placeholder="blurred" className="img-fluid" width={1440} height={592}/>
                </Col>                               
            </Row>
        </Container>
    </section>
  )
}

export default FifaArabHandlingData
