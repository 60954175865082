import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const FifaArabMain = () => {
  return (
    <div className="cs-header d-flex align-items-end">
        <Container>
            <Row className="justify-content-center">
                <Col lg={11} md={10} className="position-relative">
                    <div className="hero-image text-center">
                        <StaticImage src="../../../images/case-studies/fifa-arab-cup-2021/fifa-ac-hero-img.png" alt="Fifa Arab cup 2021 WowMakers Case study Header" placeholder="blurred" className="img-fluid" width={1291} height={703}/>
                    </div>
                </Col>
            </Row>
        </Container>
        <div className="hero-left">
            <img src="/case-studies/fifa-arab-cup-2021/fifa-ac-hero-left-img.svg" className="img-fluid" alt="Fifa Arab cup 2021 WowMakers Case study | Design pattern 1"/>
        </div>        
    </div>
  )
}

export default FifaArabMain
