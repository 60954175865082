import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const FifaArabSteps = () => {
  return (
    <section className="fifa-ac-steps pb-0 position-relative">
        <div className="right-top">
            <img src="/case-studies/fifa-arab-cup-2021/steps-top-right.svg" className="img-fluid" alt="Fifa Arab cup 2021 WowMakers Case study | Design pattern 2"/>
        </div>
        <div className="left-bottom">
            <img src="/case-studies/fifa-arab-cup-2021/steps-bottom-left.svg" className="img-fluid" alt="Fifa Arab cup 2021 WowMakers Case study | Design pattern 2"/>
        </div>                       
        <Container>
            <Row>
                <Col xl={5} lg={6} className="offset-lg-1">
                    <h2 className="sub-title">
                        Onboard as quick as the blow of a whistle in 3 steps
                    </h2>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={11} className="mt-md-5 pt-md-5 mt-0 pt-0 px-md-0 px-4">
                    <StaticImage src="../../../images/case-studies/fifa-arab-cup-2021/fifa-ac-steps.png" alt="Fifa Arab cup 2021 Steps WowMakers" placeholder="blurred" className="img-fluid" width={1207} height={824}/>
                </Col>                               
            </Row>
        </Container>
        <div className="bottom-strip">
            <img src="/case-studies/fifa-arab-cup-2021/steps-bottom-strip.png" className="img-fluid" alt="Fifa Arab cup 2021 WowMakers Case study | Design pattern 2"/>
        </div>        
    </section>
  )
}

export default FifaArabSteps
